<template>
  <section class="projects container align-center py-12 task">
    <div class="pb-4">
      <v-btn
        color="primary"
        depressed
        rounded
        small
        @click="$router.push('/tasks')"
        >{{ $t("Back to Tasks") }}
      </v-btn>
    </div>
    <div class="bg-white shadow" v-if="task">
      <div class="p-4">
        <div class="pt-4 pb-4 flex header">
          <h1 class="ticket-title flex-grow-1">
            {{ task.subject }} #{{ task.record_id }}
          </h1>
          <div class="right">
            <span
              class="ticket-label text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1"
              :style="{
                background: '#' + task.cfd_task_status_color + '80',
                color: '#fff',
              }"
              >{{ task.cfd_task_status_label }}</span
            >
          </div>
        </div>
        <div class="task-column">
          <div class="task-section">
            <div class="section-title">{{ $t("Project") }}</div>
            <div class="section-content" v-if="project">
              {{ project.project_name }}
            </div>
          </div>
          <div class="task-section">
            <div class="section-title">{{ $t("Section") }}</div>
            <div class="section-content">
              {{ task.rel_section_label }}
            </div>
          </div>
        </div>
        <div class="task-column">
          <div class="task-section">
            <div class="section-title">{{ $t("Assigned To") }}</div>
            <div class="section-content">
              <v-avatar
                color="primary"
                dark
                size="36"
                class="text-white white--text"
                >{{ task.rel_record_owner_label | firstChars }}
              </v-avatar>
              {{ task.rel_record_owner_label }}
            </div>
          </div>
          <div class="task-section">
            <div class="section-title">{{ $t("Due Date") }}</div>
            <div class="section-content">
              {{ task.end_date | date }}
              <span
                v-if="task.cfd_priority_label"
                :title="task.cfd_priority_label"
              >
                <i
                  :style="{ color: '#' + task.cfd_priority_color }"
                  :class="'fa ' + task.cfd_priority_value"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div class="task-column">
          <div class="task-section">
            <div class="section-title">{{ $t("Label") }}</div>
            <div class="section-content">
              <v-badge
                inline
                v-for="label in task.labels"
                :key="label[0]"
                :content="label[0]"
                :color="'#' + label[1]"
              />
            </div>
          </div>
        </div>

        <div class="task-column">
          <div class="task-section">
            <div class="section-title">Document</div>
            <div class="section-content">
              <v-container fluid>
                <!-- {{attachments}} -->
                <v-row dense>
                  <v-col
                    v-for="file in attachments"
                    :key="file.file_name"
                    :cols="3"
                  >
                    <v-card @click="openModal(file)">
                      <template v-if="file.isImage">
                        <v-img
                          :src="
                            'https://beta.cloud.kiva.app/support/client/attachment' +
                            '?t=' +
                            token +
                            '&taskId=' +
                            file.record_id +
                            '&fileId=' +
                            file.uid
                          "
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="140px"
                        >
                          <v-card-title v-text="file.title"></v-card-title>
                        </v-img>
                      </template>
                      <template v-else>
                        <v-img
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="140px"
                        >
                          <i
                            :class="getFileIcon(file, ' icon-file fad', 'md')"
                          ></i>
                          <v-card-title v-text="file.title"></v-card-title>
                        </v-img>
                      </template>

                      <v-card-actions>
                        {{ file.file_name | shortenFileName }}
                        <v-spacer></v-spacer>

                        <a v-if="file.isImage" target="_blank" icon>
                          <v-icon>mdi-eye</v-icon>
                        </a>
                        <a
                          :href="
                            attachmentPath +
                            '?download=1&taskId=' +
                            file.record_id +
                            '&t=' +
                            token +
                            '&fileId=' +
                            file.uid +
                             '&fn=' +  file.file_name
                          "
                          target="_blank"
                          icon
                        >
                          <v-icon>mdi-download</v-icon>
                        </a>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
        <div class="task-column">
          <div class="task-section">
            <div class="section-title">{{ $t("Description") }}</div>
            <div class="section-content" v-html="task.description">

            </div>
          </div>
        </div>
        <div class="task-column">
          <div class="task-section">
            <div class="section-title">{{ $t("Sub Tasks") }}</div>
            <div class="section-content">
              <ul class="subtasks">
                <li
                  v-for="st in subtasks"
                  :key="st.record_id"
                  :class="st.done ? 'isdone' : ''"
                >
                 <i class="fal fa-tasks"></i> {{ st.subject }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="comments">
        <div
          class="comment"
          v-for="comment in comments"
          :key="comment.record_id"
        >
          <div class="avatar">
            <v-avatar
              color="primary"
              size="36"
              v-if="comment.rel_contact_name_label"
              >{{ comment.rel_contact_name_label | firstChars }}
            </v-avatar>
            <v-avatar
              color="success"
              size="36"
              v-else-if="comment.rel_record_owner_label"
              >{{ comment.rel_record_owner_label | firstChars }}
            </v-avatar>
             <v-avatar
              color="success"
              size="36"
              v-else
              > - -
            </v-avatar>
          </div>
          <div class="comment-info">
            <div class="name">
              {{
                comment.rel_contact_name_label || comment.rel_record_owner_label || '--'
              }}
            </div>
            <div class="message">
              <comment-body
                :content="comment.body"
                :comment-id="comment.record_id"
                :editable="!!comment.editable"
                :deletable="!!comment.editable"
                :contact-name="comment.rel_contact_name_label"
                @updated="getComments"
              />
            </div>
            <div class="date">
              {{ comment.created_on | date }}
            </div>
          </div>
        </div>
        <form class="reply" @submit.prevent="submitComment">
          <v-avatar color="primary" size="36">SS</v-avatar>
          <quill-input v-model="comment" />
          <v-btn color="primary" type="submit">{{ $t("Send") }}</v-btn>
        </form>

        <v-dialog v-model="imageModal" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ selectedImage.title }}
            </v-card-title>
            <v-card-text>
              <img :src="selectedImage.src" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="imageModal = false">
                {{ $t("Close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import { TaskProxy } from "@/proxies/TaskProxy";
import { mapGetters, mapState } from "vuex";
import { CommentsProxy } from "@/proxies/CommentsProxy";
import { CreateCommentProxy } from "@/proxies/CreateCommentProxy";
import CommentBody from "@/components/CommentBody";
import fileMixin from "@/mixins/files";
import QuillInput from "@/components/QuillInput";
export default {
  name: "TaskDetail",
  components: {
    QuillInput,
    CommentBody,
  },
  mixins: [fileMixin],

  props: {
    id: { required: true },
  },
  data() {
    return {
      imageModal: false,
      selectedImage: {
        title: "",
        src: null,
      },
      task: null,
      comments: [],
      attachments: [],
      subtasks: [],
      comment: "",
    };
  },
  created() {
    this.getTask();
    this.getComments();
  },
  methods: {
    openModal(file) {
      if (file.isImage) {
        this.imageModal = true;
      }
      this.selectedImage = {
        src:
          this.attachmentPath +
          "?taskId=" +
          file.record_id +
          "&t=" +
          this.token +
          "&fileId=" +
          file.uid,
        title: "",
      };
    },

    getTask() {
      this.loading = true;
      new TaskProxy()
        .setParameters({
          taskId: this.id,
          sort: {
            name: ["sort_order"],
            desc: [false],
          },
        }) 
        .all()
        .then((response) => {
          this.task = response.data;
          let attachments = response.attachments;
          this.subtasks = response.subtasks;
          this.attachments = attachments.map((k) => {
            return { ...k, isImage: this.isImageType(k) };
          });
        });
    },
    getComments() {
      new CommentsProxy()
        .setParameters({
          taskId: this.id,
        })
        .all()
        .then((response) => {
          this.comments = response.data;
        });
    },
    submitComment() {
      new CreateCommentProxy()
        .create({
          item: {
            taskId: this.id,
            body: this.comment,
          },
        })
        .then(() => {
          this.comment = "";
          this.getComments();
        });
    },
  },
  computed: {
    ...mapGetters("common", ["getProjectById"]),
    ...mapState("account", ["token"]),

    project() {
      if (this.task !== null) {
        return this.getProjectById(this.task.project);
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
.task {
  ol,
  ul {
    padding: 0;
  }

  .header {
    border-bottom: 1px solid #d9e1ee;
    padding: 1rem;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #465669;
  }

  .task-column {
    display: flex;
    border-bottom: 1px solid #d9e1ee;
    padding: 1rem;

    > :not([hidden]) ~ :not([hidden]) {
      border-left: 1px solid #d9e1ee;
      padding-left: 1rem;
    }

    > * {
      flex: 1;
      padding: 0.5rem;
    }

    .task-section {
      display: flex;
      align-items: center;

      .section-title {
        font-weight: 500;
        font-size: 15px;
        color: #91a0b4;
        flex: none;
        width: 140px;
        position: relative;
        margin-right: 1rem;

        &:after {
          content: ":";
          position: absolute;
          right: 0;
        }
      }

      .section-content {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #465669;
      }
    }
  }

  .comments {
    padding: 12px 16px;
    background: #e3e6ec;
    box-shadow: 0px 1px 0px #dddbda;

    .comment {
      display: flex;
      margin-bottom: 1rem;

      .comment-info {
        flex: 1;
        margin-left: 1rem;

        .name {
          font-size: 13px;
          color: #006dcc;
        }

        .message {
          font-size: 13px;
          line-height: 20px;
          color: #465669;
        }

        .date {
          font-size: 12px;
          line-height: 18px;
          color: #465669;
        }
      }
    }

    .reply {
      display: flex;
      align-items: center;

      > :not([hidden]) ~ :not([hidden]) {
        margin-left: 1rem;
      }
    }
  }
}

.icon-file {
  font-size: 120px;
}

.v-responsive__content {
  text-align: center;
}
.v-card__actions{
    font-size: 12px;
}

ul.subtasks{

  li{
    padding:  12px 0px;
    border-bottom: 1px dashed #91A0B4;
    i{
      margin-right: 15px;
    }
  }

  .isdone{
    text-decoration: line-through;
    color: #91A0B4;
    font-weight: 400;
  }
}
</style>
