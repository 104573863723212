export default {

  data() {
    return {
      attachmentPath: 'https://beta.cloud.kiva.app/support/client/attachment'
    }
  },
  methods: {

    isImageType(att) {

      let name = att ? att.name ? att.name : att.file_name ? att.file_name : '' : '';

      if (name) {
        let nm = name.split(".");
        let t = nm.length - 1;
        if (nm[t]) {
          att.type = nm[t];
        }

        return att.type &&
          (att.type == "jpg" ||
            att.type == "bmp" ||
            att.type == "png" ||
            att.type == "jpeg" ||
            att.type == "gif") ?
          true :
          false;
      } else {
        return false;
      }
    },

    getFileIcon(att, design, size) {
      // console.log(att,design,size)
      let name = att ? att.name ? att.name : att.file_name ? att.file_name : '' : '';

      //   return 'fa fa-file-pdf icon-file ';
      let nm = name.split(".");

      if (!att.file_type && att.type) {
        att.file_type = att.type;
      }
      if (!att.file_id && att.id) {
        att.file_id = att.id;
      }
      let t = nm.length - 1;
      if (nm[t] || att.mime_type) {
        att.file_type = nm[t];
        if (nm[t] == "txt" || att.mime_type == "text/plain") {
          att.show_format = "iframe";
          return design + " fa-file-alt fa-" + size;
        }
        if (nm[t] == "pdf" || att.mime_type == "application/pdf") {
          att.show_format = "iframe";
          return design + " fa-file-pdf  fa-" + size;
        }
        if (nm[t] == "docx" || nm[t] == "doc") {
          att.show_format = "iframe";
          return design + " fa-file-word  fa-" + size;
        }
        if (nm[t] == "ppt" || nm[t] == "pptx") {
          att.show_format = "iframe";
          return design + " fa-file-powerpoint  fa-" + size;
        }
        if (nm[t] == "xls" || nm[t] == "xlsx") {
          att.show_format = "iframe";
          return design + " fa-file-excel  fa-" + size;
        }
        if (
          nm[t] == "zip" ||
          nm[t] == "tar" ||
          nm[t] == "rar" ||
          att.mime_type == "application/zip" ||
          att.mime_type == "application/rar"
        ) {
          att.show_format = "iframe";
          return design + " fa-file-archive  fa-" + size;
        }
        if (nm[t] == "csv") {
          att.show_format = "iframe";
          return design + " fa-file-csv  fa-" + size;
        }
        if (
          nm[t] == "mp4" ||
          nm[t] == "webm" ||
          nm[t] == "mpeg" ||
          nm[t] == "avi" ||
          nm[t] == "wmv" ||
          nm[t] == "mov" ||
          nm[t] == "flv"
        ) {
          att.show_format = "iframe";
          return design + " fa-file-video  fa-" + size;
        }
        if (
          nm[t] == "jpg" ||
          nm[t] == "jpeg" ||
          nm[t] == "png" ||
          nm[t] == "PNG" ||
          nm[t] == "gif"
        ) {
          att.show_format = "img";
          return design + " fa-file-image fa-" + size;
        }
      }
    },
  }

}
